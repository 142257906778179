import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/services/authguard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
// import { VerificationcodeComponent } from './components/verificationcode/verificationcode.component';
import { EmailenterComponent } from './public/emailenter/emailenter.component';
// import { UserwizardComponent } from './components/userwizard/userwizard.component';
import { GoogleMapComponent } from './profile/google-map/google-map.component';


const routes: Routes = [
 
  {
    path:'Profile',component: LayoutComponent, loadChildren:() => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate:[AuthGuard]
  },
  {
    path:'schedules',component: LayoutComponent, loadChildren:() => import('./schedules/schedules.module').then(m => m.SchedulesModule),
    canActivate:[AuthGuard]
  },
 
  {
    path: "google-map/:latitude/:longitude",
    component: GoogleMapComponent,
   
  },
  {
    path:'Dobby', component: LayoutComponent, canActivate: [AuthGuard], children: [
      {
        path: 'Dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'Facesheet', loadChildren: () => import('./aggridtesting/aggridtesting.module').then(m => m.AggridtestingModule)
      },
      {
        path: "Issues", loadChildren: () => import('./issues/issues.module').then(m => m.IssuesModule),
        canActivate:[AuthGuard]
      },
      { 
        path: "Actions", loadChildren: () => import('./actions/actions.module').then(n =>n.ActionsModule),
        canActivate:[AuthGuard]
      },
      {
         path:"Templates", loadChildren:() => import('./template/template.module').then(n => n.TemplateModule),
         canActivate:[AuthGuard] 
      },
      { 
        path:"Sites", loadChildren:() => import('./sites/sites.module').then(n => n.SitesModule),
        canActivate:[AuthGuard]
      },
      {
        path:'Audits', loadChildren:() => import('./scheduled/scheduled.module').then(m => m.ScheduledModule),
        canActivate:[AuthGuard]
      },
      {
        path:'Settings', loadChildren:() => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate:[AuthGuard]
      },
      {
        path:'Reviews', loadChildren:() => import('./reviews/reviews.module').then(m => m.ReviewsModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'Calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
        canActivate:[AuthGuard]
      },
     
      {
        path: 'Reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'PMSData',
        loadChildren: () => import('./excel/excel.module').then(m => m.ExcelModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'PatientBilling',
      loadChildren: () => import('./residentbilling/residentbilling.module').then(m => m.ResidentbillingModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'FaceSheet', loadChildren: () => import('./facesheet/facesheet.module').then(m => m.FacesheetModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'PrivacyPolicy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'Martar', loadChildren: () => import('./martar/martar.module').then(m => m.MartarModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'MartarImport', loadChildren: () => import('./martarimport/martarimport.module').then(m => m.MartarimportModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'Census', loadChildren: () => import('./census/census.module').then(m => m.CensusModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'MartarCombine', loadChildren: () => import('./martar-combine/martar-combine.module').then(m => m.MartarCombineModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'CensusPlusFacesheet', loadChildren: () => import('./censusplusfacesheet/censusplusfacesheet.module').then(m => m.CensusPlusFacesheetModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'CensusCombine', loadChildren: () => import('./census-combine/census-combine.module').then(m => m.CensusCombineModule),
        canActivate:[AuthGuard]
      },
    ]
  },
 
  {
    path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },

  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
